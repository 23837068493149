import React, { ReactNode } from 'react'
import { useDispatch } from 'react-redux'
import {
  ColumnDef,
  flexRender,
  getCoreRowModel,
  useReactTable
} from '@tanstack/react-table'
import { Box, Flex, Text } from '@chakra-ui/react'
import CustomText from 'components/atoms/CustomText'
import Status from 'components/atoms/Status'
import setShowModal, {
  setShowDrawerAccount,
  setShowDrawerGrains
} from 'store/modals/actions'
import {
  firstLetterUpper,
  formatDateWithMonthOptional,
  getInitials,
  numberWithCommas
} from '@qirapagos/lib/utils/common'
import {
  MovementItem,
  FilterTable,
  CustomSearch,
  AvatarOutline,
  TableActionButton
} from 'components'
import { addCommaZero, getStatusType } from 'utils/common'
import useNavigation from 'hooks/useNavigation'
import TableDesktopSkeleton from 'assets/skeletons/TableDesktopSkeleton'
import ButtonLine from 'components/atoms/ButtonLine'
import EmptyCabinetSVG from 'assets/svg/EmptyCabinetSVG'
import { useAppSelector } from 'hooks/redux'
import { signStatus, signStatusNum } from './types'
import {
  BoxSignedDocument,
  EmptyTextContainer,
  FiltersContainer,
  HeaderContainer,
  HowWorksContainer,
  IconContainer,
  styles,
  SvgContainer,
  TableContainer,
  TableDataItem,
  TableHeader,
  TableHeaderItem,
  TableRow,
  TableStyled,
  Title,
  SpinnerIcon
} from './styles'
import { black } from 'theme/colors'
import { setDocumentLink } from 'store/signature/actions'
import { downloadDocument } from 'store/signature/thunks'
import { StatusType } from 'components/atoms/Status/constants'

interface Props {
  title: string
  hasHowWorks?: boolean
  data: any[]
  config: any
  isTextFilter?: boolean
  onSearchText?: any
  searchValue?: string
  fromScreen?: string
  isLoading?: boolean
  filterOptions?: string[]
  onFilter?: any
  icon?: ReactNode
  onClick?: () => void
  hasDateFilter?: boolean
  showFilters?: boolean
  emptyText?: string
}

interface IDotActionConfig {
  title: string
  onPress: () => void
}

const Table: React.FC<Props> = ({
  title,
  icon,
  hasHowWorks,
  data,
  onClick = () => null,
  config,
  isTextFilter = false,
  onSearchText,
  searchValue = '',
  fromScreen,
  isLoading,
  filterOptions,
  onFilter = () => null,
  hasDateFilter = true,
  showFilters = true,
  emptyText = ''
}) => {
  const dispatch = useDispatch()
  const { onNavigate } = useNavigation()
  const isEmpty = data.length === 0
  const { showDrawer, drawerGrains } = useAppSelector(state => state.modal)
  const { isDownloadingFile } = useAppSelector(state => state.signature)
  let drawerConfig

  const cellGenerator = (id: string, info: any) => {
    if (id === 'actions') {
      let dotActionConfig: Array<IDotActionConfig> = []
      if (fromScreen === 'newTransfer') {
        dotActionConfig = [
          {
            title: 'Ver detalle',
            onPress: () => {
              drawerConfig = {
                title: 'Comprobante de transferencia',
                config: 'horizontal'
              }
              dispatch(
                setShowDrawerAccount(
                  !showDrawer,
                  'transferData',
                  drawerConfig,
                  info.row.original
                )
              )
            }
          },
          {
            title: 'Repetir transferencia',
            onPress: () =>
              onNavigate('transfer-steps', {
                title: 'Repetir transferencia',
                hasBackArrow: true,
                fromRepeatTransfer: true,
                dataAddressee: info.row.original?.alias,
                amountAddressee: info.row.original?.amount
              })
          }
        ]
      }

      if (fromScreen === 'signature') {
        const signed = info.row.original.signingProcessStatusId === signStatusNum.SIGNED
        const { index, original: { link, documentId } } = info.row
        const isDownloading = isDownloadingFile === index

        if (signed) {
          dotActionConfig = [
            {
              title: 'Descargar',
              onPress: () => {
                dispatch(downloadDocument(documentId, index))
              }
            }
          ]
        } else {
          dotActionConfig = [
            {
              title: 'Firmar',
              onPress: () => {
                dispatch(setDocumentLink(link))
                dispatch(setShowModal('signature'))
              }
            }
          ]
        }
        if (isDownloading) {
          return (
            <SpinnerIcon />
          )
        }
        return (
          <TableActionButton
            placement="left"
            dotActionConfig={dotActionConfig}
          />
        )
      }

      if (fromScreen === 'newTransferContacts') {
        dotActionConfig = [
          {
            title: 'Transferir',
            onPress: () =>
              onNavigate('/content/your-account/new-transfer/transfer-steps', {
                title: 'Nueva transferencia',
                hasBackArrow: true,
                fromRepeatTransfer: true,
                dataAddressee: info.row.original?.cvu,
                amountAddressee: 0
              })
          },
          {
            title: 'Editar observaciones',
            onPress: () => {
              onNavigate('/content/your-account/my-contacts/edit-contact', {
                title: 'Editar contacto',
                hasBackArrow: true,
                name: info.row.original?.name,
                cvu: info.row.original?.cvu,
                observations: info.row.original?.observations,
                account: info.row.original?.account,
                dataAddressee: info.row.original?.cvu,
                id: info.row.original?.id,
                alias: info.row.original?.alias,
                amountAddressee: 0
              })
            }
          },
          {
            title: 'Eliminar contacto',
            onPress: () =>
              onNavigate('/content/your-account/my-contacts/delete-contact', {
                title: 'Eliminar contacto',
                hasBackArrow: true,
                name: info.row.original?.name,
                cvu: info.row.original?.cvu,
                account: info.row.original?.account,
                dataAddressee: info.row.original?.cvu,
                observation: info.row.original?.observations,
                id: info.row.original?.id
              })
          }
        ]
        return (
          <TableActionButton
            placement="left"
            dotActionConfig={dotActionConfig}
          />
        )
      }

      if (fromScreen === 'yourAccount') {
        if (info.row.original?.movementType === 'saleOrder') {
          return onNavigate('ActivityGrains')
        }

        dotActionConfig = [
          {
            title: 'Ver comprobante',
            onPress: () => {
              drawerConfig = {
                title: '',
                config: 'horizontal'
              }
              dispatch(
                setShowDrawerAccount(
                  !showDrawer,
                  'transferData',
                  drawerConfig,
                  info.row.original
                )
              )
            }
          }
        ]

        if (
          info.row.original.movementType?.typeDescription?.includes('Débito')
        ) {
          dotActionConfig.push({
            title: 'Repetir transferencia',
            onPress: () =>
              onNavigate('/content/your-account/new-transfer/transfer-steps', {
                title: 'Repetir transferencia',
                hasBackArrow: true,
                fromRepeatTransfer: true,
                dataAddressee: info.row.original?.alias,
                amountAddressee: info.row.original?.amount
              })
          })
        }
      }

      if (fromScreen === 'grain') {
        dotActionConfig = [
          {
            title: 'Ver actividad',
            onPress: () => {
              return onNavigate('activity', {
                operationId: info.row.original.operationId,
                operationTitle: info.row.original.title,
                title: 'Detalle de tu actividad'
              })
            }
          }
        ]
        return (
          <TableActionButton
            placement="left"
            dotActionConfig={dotActionConfig}
          />
        )
      }

      if (fromScreen === 'grainActivity') {
        dotActionConfig = [
          {
            title: 'Ver detalle',
            onPress: () => {
              drawerConfig = {
                title: 'Detalle de contrato',
                config: 'horizontal'
              }
              dispatch(
                setShowDrawerGrains(
                  !drawerGrains.showDrawerGrains,
                  drawerConfig,
                  info.row.original
                )
              )
            }
          }
        ]
        return (
          <TableActionButton
            placement="bottom-end"
            dotActionConfig={dotActionConfig}
          />
        )
      }

      return (
        <TableActionButton placement="left" dotActionConfig={dotActionConfig} />
      )
    }

    if (id === 'title') {
      return <MovementItem movementType={info.getValue()} fromContracts />
    }

    if (id === 'weight') {
      return (
        <CustomText size="xmedium" weight="mediumfont">
          {info.getValue()}
          {' '}
          TT
        </CustomText>
      )
    }

    if (id === 'processStartDate') {
      return (
        <Text fontWeight={500} align="center" fontSize={{ md: '13px', lg: '16px' }}>
          {formatDateWithMonthOptional(info.getValue())}
        </Text>
      )
    }

    if (id === 'date') {
      return (
        <Text
          fontWeight={500}
          align={fromScreen === 'newTransfer' ? 'left' : 'center'}
          fontSize={{ md: '13px', lg: '16px' }}
        >
          {formatDateWithMonthOptional(info.getValue())}
        </Text>
      )
    }

    if (id === 'deliveryDate') {
      const [year, month, day] = info.getValue().split('-')
      const correctDateString = `${year}-${day}-${month}`
      return (
        <Text fontWeight={500} fontSize={{ md: '13px', lg: '16px' }}>
          {correctDateString}
        </Text>
      )
    }
    if (id === 'creationDate') {
      return (
        <CustomText align="center" size="xmedium" weight="mediumfont">
          {formatDateWithMonthOptional(info.getValue())}
        </CustomText>
      )
    }

    if (id === 'price') {
      return (
        <CustomText size="xmedium" weight="mediumfont">
          {!info.getValue()
            ? 'A fijar'
            : `$ ${numberWithCommas(info.getValue())}`}
        </CustomText>
      )
    }

    if (id === 'owner') {
      if (info.row.original.owner?.trim() === 'Crédito Transferencia') {
        return <CustomText>-</CustomText>
      }
      return (
        <CustomText size="xmedium" weight="mediumfont">
          {info.getValue()}
        </CustomText>
      )
    }

    if (id === 'concept') {
      return (
        <Text fontWeight={500} align="center" fontSize={{ md: '13px', lg: '16px' }}>
          Varios
        </Text>
      )
    }

    if (id === 'amount') {
      const amountToLocaleString = info.getValue().toLocaleString('es-AR')
      return (
        <Text fontWeight={500} align="center" fontSize={{ md: '13px', lg: '16px' }}>
          $
          {amountToLocaleString}
          {addCommaZero(amountToLocaleString)}
        </Text>
      )
    }

    if (id === 'movementType') {
      return (
        <MovementItem
          movementType={info.getValue()}
          owner={info.row.original.owner}
        />
      )
    }

    if (id === 'phone') {
      return (
        <CustomText
          customStyle={{ textAlign: 'left' }}
          size="xmedium"
          weight="mediumfont"
        >
          {info?.getValue()?.substring(4)}
        </CustomText>
      )
    }

    if (id === 'address') {
      return (
        <CustomText
          customStyle={{ textAlign: 'left' }}
          size="xmedium"
          weight="mediumfont"
        >
          {info.getValue()}
        </CustomText>
      )
    }

    if (id === 'description') {
      return (
        <CustomText
          customStyle={{ textAlign: 'left' }}
          size="xmedium"
          weight="mediumfont"
        >
          {info.getValue()}
        </CustomText>
      )
    }

    if (id === 'grain') {
      return (
        <Text fontWeight={550} fontSize={{ md: '13px', lg: '15px' }}>
          {firstLetterUpper(info.getValue())}
        </Text>
      )
    }

    if (id === 'status') {
      if (info.column.columnDef.header === 'Mis Cupos') {
        return (
          <MovementItem
            movementType="Cupo"
            status={info.getValue().toLowerCase()}
            fromContracts
          />
        )
      }
      if (info.column.columnDef.header === 'Mis Fijaciones') {
        return (
          <MovementItem
            movementType="priceFixation"
            status={info.getValue()}
            fromContracts
          />
        )
      }

      if (getStatusType(info.getValue()) === 'NEW') {
        return (
          <Text textTransform="capitalize">
            {info.getValue().toLowerCase()}
          </Text>
        )
      }
      return (
        <Status
          customStyle={styles.status}
          statusType={getStatusType(info.getValue()) as StatusType}
        />
      )
    }

    if (id === 'companyname' || id === 'name') {
      return (
        <Flex alignItems="center">
          <AvatarOutline initials={getInitials(info.getValue()) || ''} />
          <CustomText
            customStyle={{ marginLeft: 20, width: 250, textAlign: 'left' }}
            size="xmedium"
            weight="mediumfont"
          >
            {info.getValue()}
          </CustomText>
        </Flex>
      )
    }

    if (id === 'cupoCode') {
      return (
        <MovementItem
          movementType="deliveries"
          status={info.row.original.porteNumber}
          fromContracts
        />
      )
    }

    if (id === 'type') {
      return (
        <MovementItem
          movementType="settlements"
          status={info.getValue()}
          fromContracts
        />
      )
    }

    if (id === 'settlementDate') {
      return (
        <CustomText weight="mediumfont" size="xmedium">
          {formatDateWithMonthOptional(info.getValue())}
        </CustomText>
      )
    }

    if (id === 'signingProcessStatusId') {
      const signed = info.getValue() === signStatusNum.SIGNED
      return (
        <BoxSignedDocument signed={signed}>
          <CustomText
            weight="semibold"
            size="xmedium"
            color={black}
          >
            {signed ? signStatus.SIGNED : signStatus.PENDING}
          </CustomText>
        </BoxSignedDocument>
      )
    }
    if (id === 'documentName') {
      return (
        <MovementItem
          movementType="documents"
          status={info.getValue()}
          fromContracts
        />
      )
    }
    if (id === 'documentCompany') {
      return (
        <CustomText weight="mediumfont" size="xmedium" align='left'>
          {info.getValue()}
        </CustomText>
      )
    }
    if (id === 'signDate') {
      return (
        <CustomText weight="mediumfont" size="xmedium">
          {info.getValue()}
        </CustomText>
      )
    }
    return (
      <CustomText size="xmedium" weight="mediumfont">
        {info.getValue() || 'Sin Datos'}
      </CustomText>
    )
  }

  const columns: ColumnDef<any>[] = config.map((column: any) => {
    const { id, header } = column
    return {
      accessorKey: id,
      id,
      cell: (info: any) => cellGenerator(id, info),
      header
    }
  })

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel()
  })

  if (isLoading) return <TableDesktopSkeleton />
  return (
    <TableContainer>
      <HeaderContainer>
        <Flex>
          <Title>{title}</Title>
          {icon && (
            <IconContainer>
              <button type="button" onClick={onClick}>
                {icon}
              </button>
            </IconContainer>
          )}
        </Flex>
        {hasHowWorks && (
          <HowWorksContainer>
            <ButtonLine onPress={onClick}>¿Cómo funciona?</ButtonLine>
          </HowWorksContainer>
        )}
        <FiltersContainer>
          {isTextFilter && (
            <Box h={10} mt={4}>
              <CustomSearch
                onChange={onSearchText}
                value={searchValue}
                setIsSearchFocused={() => null}
                isSearchFocused
                placeholder="Buscar"
              />
            </Box>
          )}
          {showFilters
            ? (
              <FilterTable filterOptions={filterOptions} onFilter={onFilter} />
              )
            : <Box />}
          {hasDateFilter && <FilterTable shouldOpenDrawer />}
        </FiltersContainer>
      </HeaderContainer>
      {isEmpty
        ? (
          <SvgContainer id="empty-list-movements">
            <EmptyCabinetSVG />
            <EmptyTextContainer>{emptyText}</EmptyTextContainer>
          </SvgContainer>
          )
        : (
          <TableStyled>
            <TableHeader isTextFilter={isTextFilter}>
              {table.getHeaderGroups().map(headerGroup => (
                <tr key={headerGroup.id}>
                  {headerGroup.headers.map((header, index) => (
                    <TableHeaderItem index={index} key={header.id}>
                      {flexRender(
                        header.column.columnDef.header,
                        header.getContext()
                      )}
                    </TableHeaderItem>
                  ))}
                </tr>
              ))}
            </TableHeader>
            <tbody id="table-body">
              {table.getRowModel().rows.map((row, index) => (
                <TableRow index={index} key={row.id}>
                  {row.getVisibleCells().map((cell, index) => (
                    <TableDataItem index={index} key={cell.id}>
                      {flexRender(cell.column.columnDef.cell, cell.getContext())}
                    </TableDataItem>
                  ))}
                </TableRow>
              ))}
            </tbody>
          </TableStyled>
          )}
    </TableContainer>
  )
}

export default Table

Table.defaultProps = {
  onSearchText: () => null,
  searchValue: '',
  isTextFilter: false,
  fromScreen: '',
  isLoading: false,
  filterOptions: [],
  onFilter: () => null,
  hasDateFilter: true,
  showFilters: true,
  icon: undefined,
  onClick: () => null,
  hasHowWorks: false,
  emptyText: ''
}
