import { StyleSheet } from 'react-native'
import styled from 'styled-components'
import {
  Text as ChakraText,
  TextProps as ChakraTextProps
} from '@chakra-ui/react'
import { Colors, Fonts } from '@qirapagos/web/src/theme'
import { Alignment, FontWeight, FontSize } from 'types/fontStyles'

interface TextProps extends Omit<ChakraTextProps, 'size'> {
  size?: FontSize | number
  weight?: FontWeight
  align?: Alignment
  lineHeight?: number
  underlined?: boolean
}

export const getFontColor = (color: string) => {
  if (color === 'error') return Colors.error
  if (color === 'blackSec') return Colors.blackSec
  if (color === 'white') return Colors.white
  if (color === 'green') return Colors.green
  if (color === 'purple') return Colors.purple
  if (color === 'violet') return Colors.violet
  if (color === 'gray') return Colors.gray
  if (color === 'grayTextFilter') return Colors.grayTextFilter
  if (color === 'yellow') return Colors.yellow
  if (color === 'red') return Colors.red
  if (color === 'blue') return Colors.blue
  if (color === 'deselect') return Colors.graySec
  if (color === 'grayText') return Colors.grayText
  if (color === 'darkGray') return Colors.darkGray
  if (color === 'disabled') return 'rgba(22, 28, 39, 0.5)'
  return Colors.black
}

const getFontStyleByWeight = (weight: FontWeight) => {
  if (weight === 'regular') return 400
  if (weight === 'mediumfont') return 500
  return 600
}

const getFontSize = (size: FontSize) => {
  if (size === 'small') return Fonts.SIZES.SMALL
  if (size === 'medium') return Fonts.SIZES.MEDIUM
  if (size === 'xmedium') return Fonts.SIZES.XMEDIUM
  if (size === 'big') return Fonts.SIZES.BIG
  if (size === 'mbig') return Fonts.SIZES.MBIG
  if (size === 'mmbig') return Fonts.SIZES.MMBIG
  if (size === 'xbig') return Fonts.SIZES.XBIG
  if (size === 'large') return Fonts.SIZES.LARGE
  if (size === 'xlarge') return Fonts.SIZES.XLARGE
  return Fonts.SIZES.XXLARGE
}

export const StyledText = styled(ChakraText)<TextProps>`
  font-weight: ${({ weight }) => getFontStyleByWeight(weight || 'mediumfont')};
  font-family: ${Fonts.MANROPE};
  font-size: ${({ size }) => getFontSize(size || 'medium')}px;
  text-align: ${({ align }) => align || 'center'};
  line-height: 24.752px;
  text-decoration: ${({ underlined }) => (underlined ? 'underline' : 'none')};
`

export default StyleSheet.create({
  default: {
    color: Colors.black,
    fontFamily: Fonts.POPPINS_MED,
    fontWeight: '500',
    fontSize: Fonts.SIZES.SMALL,
    textAlign: 'center'
  }
})
