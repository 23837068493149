import React from 'react'
import Drawer from 'react-modern-drawer'
import { useDispatch } from 'react-redux'
import { setShowDrawerAccount } from 'store/modals/actions'
import BackArrowIcon from '@qirapagos/web/src/assets/svg/BackArrow'
import { RenderIf, AccountInfoList, TransferInfoList } from 'components'
import useSharePdf from 'hooks/useSharePdf'
import {
  LogoContainer,
  ButtonContainer,
  DownloadButton,
  HeaderContainer,
  DrawerContainer,
  BackButton,
  DataContainer,
  getDrawerStyles,
  SpinnerContainer,
  ToastRenderItem,
  ReceiptDetailText
} from './styles'
import QirapagosLogoWeb from '@qirapagos/web/src/assets/svg/QirapagosLogo'
import { violet } from '@qirapagos/lib/theme/colors'
import { Spinner, useToast } from '@chakra-ui/react'
import { useAppSelector } from 'hooks/redux'
import SuccessTickWithBackground from 'assets/svg/SuccessTickWithBackground'
import useNavigation from 'hooks/useNavigation'

const DrawerReceipt: React.FC = () => {
  const { drawerConfig, selectedReceipt, drawerInfo, showAccountDrawer } =
    useAppSelector((state) => state.modal)
  const { isLoading } = useAppSelector(state => state.transfers)
  const { onNavigate } = useNavigation()
  const dispatch = useDispatch()
  const width = window.innerWidth
  const toast = useToast({
    containerStyle: {
      width: '100%'
    }
  })
  const widthForUseSharePDF =
    width < 600
      ? (window.innerWidth * 1.26) / 2
      : width < 1024
        ? (window.innerWidth * 0.4 * 1.26) / 2
        : (window.innerWidth * 0.3 * 1.26) / 2

  const { printRef, onShareHandler } = useSharePdf(
    (window.innerHeight * 1.26) / 2,
    widthForUseSharePDF
  )

  const onClose = () => {
    if (drawerConfig?.title === 'Transferencia') {
      dispatch(setShowDrawerAccount(false, selectedReceipt, drawerConfig, drawerInfo))
      onNavigate('/content/your-account/new-transfer', {
        title: 'Nueva transferencia',
        hasBackArrow: true
      })
      return
    }

    dispatch(setShowDrawerAccount(false, selectedReceipt, drawerConfig, drawerInfo))
  }

  return (
    <Drawer
      open={showAccountDrawer}
      onClose={onClose}
      direction="right"
      style={getDrawerStyles()}
    >
      {isLoading
        ? (
          <SpinnerContainer>
            <Spinner />
          </SpinnerContainer>
          )
        : (
          <DrawerContainer>
            <BackButton onClick={onClose}>
              <BackArrowIcon />
            </BackButton>
            <div ref={printRef}>
              <HeaderContainer>
                <LogoContainer>
                  <QirapagosLogoWeb color={violet} width={150} height={35} />
                  <ReceiptDetailText>
                    Detalle de comprobante
                  </ReceiptDetailText>
                </LogoContainer>
              </HeaderContainer>
              <DataContainer>
                <RenderIf isTrue={selectedReceipt === 'accountData'}>
                  <AccountInfoList />
                </RenderIf>
                <RenderIf isTrue={selectedReceipt === 'transferData'}>
                  <TransferInfoList />
                </RenderIf>
              </DataContainer>
            </div>
            <ButtonContainer>
              <DownloadButton
                onPress={() => {
                  toast({
                    position: 'bottom',
                    render: () => (
                      <ToastRenderItem>
                        El comprobante se ha descargardo correctamente
                        <SuccessTickWithBackground />
                      </ToastRenderItem>
                    )
                  })
                  onShareHandler()
                }}
              >
                Descargar PDF
              </DownloadButton>
            </ButtonContainer>
          </DrawerContainer>
          )}
    </Drawer>
  )
}

export default DrawerReceipt
