import { getDate, getMonth, getYear } from 'date-fns'
import moment from 'moment'

export function isCuitValid (cuit: string): {
  canValidate: boolean
  isValid: boolean
  isBusiness: boolean
} {
  const regexCuit = /^(20|23|24|27|30|33|34)([0-9]{9}|-[0-9]{8}-[0-9]{1})$/g
  const startCuit = cuit?.slice(0, 2)
  const canValidate = cuit?.length == 11
  const regexBusiness = /^(30|33|34)$/g
  const isBusiness = regexBusiness.test(startCuit)
  const isValid = regexCuit.test(cuit)
  return { canValidate, isValid, isBusiness }
}

const MONTHS = [
  'Ene',
  'Feb',
  'Mar',
  'Abr',
  'May',
  'Jun',
  'Jul',
  'Ago',
  'Sep',
  'Oct',
  'Nov',
  'Dic'
]

const MONTHSFULLNAME = [
  'Enero',
  'Febrero',
  'Marzo',
  'Abril',
  'Mayo',
  'Junio',
  'Julio',
  'Agosto',
  'Septiembre',
  'Octubre',
  'Noviembre',
  'Diciembre'
]

export function stringDate () {
  const today = new Date()
  const day = today.getDate()
  const month = today.getMonth()
  const monthInText = MONTHS[month].toUpperCase()
  const year = today.getFullYear()
  const date = `${day} ${monthInText} ${year}`
  return date
}

export function getInitials (name: string) {
  if (!name) return 'AA'
  if (!name.includes(' ')) return name.substring(0, 2)
  return name.split(' ')[0].charAt(0) + name.split(' ')[1].charAt(0)
}

export const formatPhoneNumber = (number: string) => {
  const formattedNumber = number.replace(
    /[`~!@#$%^&*()_|+\-=?;:'",.<>( )]/gi,
    ''
  )
  return formattedNumber
}

export function numberWithCommas (value: number | string) {
  if (!value) return ''
  return value.toLocaleString('es-AR')
}

export function formatDateToBackend (date: Date) {
  /*
    TODO: En un futuro cuando se mande hora si se envia 03:00:00 no funciona,
    el backend pide 3:00:00 y si son las 15: 15:00:00
  */
  date.setUTCHours(0, 0, 0, 0)
  return date.toISOString().substr(0, 19).replace('T', ' ') // yyyy-mm-dd HH:MM:SS
}

export function formatDateForFilter (date: Date) {
  const days = getDate(date)
  const month = MONTHS[getMonth(date)]
  const year = getYear(date)
  return `${days} ${month} ${year}`
}

export function formatDate (date: string) {
  return date?.split('T')[0].split('-').reverse().join('/')
}

export function formatDateWithMonthOptional (date: string) {
  if (!date) return ''
  const formatedArray = date.split('T')[0].split('-').reverse()
  const month =
    formatedArray[1].split('')[0] === '0'
      ? MONTHS[Number(formatedArray[1].split('')[1]) - 1]
      : MONTHS[Number(formatedArray[1]) - 1]
  return `${formatedArray[0]} ${month} ${formatedArray[2]}`
}

export function formatName (name: string) {
  const splited = `${name.split(' ')[0]} ${name.split(' ')[1]}`
  const passLimit = name.length > 12 ? '...' : ''
  return (
    splited
      .toLowerCase()
      .split(' ')
      .map(el => el.charAt(0).toUpperCase() + el.slice(1))
      .join(' ')
      .trim() + passLimit
  )
}

export function formatNameWithNoLimit (name: string) {
  let amountOfWords = 0
  let splited = ''

  for (let x = 0; x < name.length; x++) {
    if (name[x] === ' ') {
      amountOfWords = amountOfWords + 1
    }
  }
  for (let x = 0; x <= amountOfWords; x++) {
    splited += `${name.split(' ')[x]} `
  }
  return splited
    .toLowerCase()
    .split(' ')
    .map(el => el.charAt(0).toUpperCase() + el.slice(1))
    .join(' ')
    .trim()
}

export function cutTextEightChars (string: string) {
  return `${string.substring(0, 8)}...`
}

export function cutTextFifteenChars (string: string) {
  if (string.length < 16) return string
  return `${string.substring(0, 15)}...`
}

export function cutTextTwentyChars (string: string) {
  if (string.length < 21) return string
  return `${string.substring(0, 20)}...`
}

export function cutTextTwentyFiveChars (string: string) {
  if (string.length < 26) return string
  return `${string.substring(0, 25)}...`
}

export function formatDecimalsHelper (balance: string) {
  let balanceWithNoDecimals = '0'
  let decimals = '00'

  if (balance) {
    if (!balance.includes(',')) {
      balance = `${balance},00`
    }
    balanceWithNoDecimals = balance.split(',')[0]
    decimals = balance.split(',')[1] || '00'
    if (decimals.length === 1) {
      decimals = `${decimals}0`
    }
  }

  return { balanceWithNoDecimals, decimals }
}

export function formatNumbersHelper (number: number | string) {
  if (typeof number === 'number') {
    const numberNumber = number.toLocaleString('es-AR')
    return numberNumber
  }
  const numberString = parseFloat(number).toLocaleString('es-AR')
  return numberString
}

export function firstLetterUpper (string: string) {
  if (string === undefined) return ''
  return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase()
}

const DAYS = [
  'lunes',
  'martes',
  'miércoles',
  'jueves',
  'viernes',
  'sábado',
  'domingo'
]

export function formatDateWithMonth (date: string) {
  const formatedArray = date.split('T')[0].split('-').reverse()
  const month =
    formatedArray[1].split('')[0] === '0'
      ? MONTHS[Number(formatedArray[1].split('')[1]) - 1]
      : MONTHS[Number(formatedArray[1]) - 1]
  return `${formatedArray[0]} ${month} ${formatedArray[2]}`
}

export function formatDateOnlyMonth (date: string) {
  const formatedArray = date.split('T')[0].split('-').reverse()
  const month =
    formatedArray[1].split('')[0] === '0'
      ? MONTHSFULLNAME[Number(formatedArray[1].split('')[1]) - 1]
      : MONTHSFULLNAME[Number(formatedArray[1]) - 1]
  return ` ${month}`
}

export function formatDateWithMonthInCupos (date: string) {
  const formatedArray = date.split('T')[0].split('-').reverse()
  const month =
    formatedArray[1].split('')[0] === '0'
      ? MONTHS[Number(formatedArray[1].split('')[1])]
      : MONTHS[Number(formatedArray[1])]
  return `${formatedArray[0]} ${month} ${formatedArray[2]}`
}

export function formatDateWithMonthAndDayName (date: string) {
  const formatedArray = date.split('T')[0].split('-').reverse()
  const d = new Date(date)
  const day = DAYS[d.getDay()]
  const month =
    formatedArray[1].split('')[0] === '0'
      ? MONTHS[Number(formatedArray[1].split('')[1])]
      : MONTHS[Number(formatedArray[1])]
  return ` ${formatedArray[0]} ${month} ${formatedArray[2]}, ${day}`
}

export const APROVED = 'APROVED'
export const PENDING = 'PENDING'
export const ONBOARDING = 'ONBOARDING'
export const UNSUBSCRIBED = 'UNSUBSCRIBED'
export const SUSPENDED = 'SUSPENDED'

export function getDemoStatus (onboardingStatus: number, companyStatus: number) {
  if (companyStatus === 2) {
    return APROVED
  }
  if (companyStatus === 5) {
    return SUSPENDED
  }
  if (companyStatus === 4) {
    return UNSUBSCRIBED
  }
  if (onboardingStatus === 4 && companyStatus === 1) {
    return PENDING
  }
  return ONBOARDING
}

export function cutLongText (text: string, maxLength: number) {
  const result = `${text.substring(0, maxLength)}...`

  return result
}

export async function goValidationStep (onboardingStatus: number) {
  // switch (onboardingStatus) {
  //   case 0:
  //     resetFromRoot('DigitalOnboarding', {}, 'DOValidate')
  //     break
  //   case 1:
  //     resetFromRoot('DigitalOnboarding', {}, 'DONumberRegister')
  //     break
  //   case 2:
  //     resetFromRoot('DigitalOnboarding', {}, 'DOSelfieUpload')
  //     break
  //   case 3:
  //     resetFromRoot('DigitalOnboarding', { fromHome: true }, 'DODeclaration')
  //     break
  //   case 4:
  //     const isBusiness = await localStorage.getItem('isBusiness')
  //     const docs = await localStorage.getItem('docs')

  //     if (docs === 'false' && isBusiness === 'true') {
  //       resetFromRoot('DigitalOnboarding', {}, 'DODocuments')
  //     } else {
  //     }
  //     break
  //   default:
  //     resetFromRoot('DigitalOnboarding', {}, 'DOValidate')
  //     break
  // }
  // // }
}

export const WhatsAppContactNumber = '543416901487'

export function getDeliveryPeriodName (dateFrom: Date, dateTo: Date) {
  const DifferenceInTime = dateTo.getTime() - dateFrom.getTime()
  const DifferenceInDays = DifferenceInTime / (1000 * 3600 * 24)

  switch (true) {
    case DifferenceInDays === 7:
      return 'Entrega inmediata (7 días)'
    case DifferenceInDays === 30:
      return 'Entrega contractual (30 días)'
    default:
      return 'Entrega a futuro'
  }
}

// With this function we disable weekends and days before today in the datepicker
export const isValidDate = (current: {
  isAfter: (arg0: moment.Moment) => any
  day: () => number
}) => {
  const yesterday = moment().subtract(1, 'day')
  return current.isAfter(yesterday)
}

export const isValidDateIn2Years = (current: {
  isAfter: (arg0: moment.Moment) => any
  day: () => number
}) => {
  const twoYears = moment().add(2, 'years')
  return !current.isAfter(twoYears)
}

export const AnalyticsEvents = {
  PANTALLA_INICIO: 'pantalla_inicio',
  RECUPERAR_CONTRASENA: 'recuperar_contrasena',
  INICIAR_SESION: 'iniciar_sesion',
  REGISTRO: 'registro',
  INICIAR_SESION_EXITO: 'iniciar_sesion_exito',
  REGISTRO_EXITO: 'registro_exito',
  RECUPERAR_CONTRASENA_EXITO: 'recuperar_contrasena_exito',
  INICIAR_VALIDACION: 'iniciar_validacion',
  VALIDACION_PERSONA_JURIDICA: 'validacion_persona_juridica',
  VALIDACION_PERSONA_JURIDICA_TELEFONO: 'validacion_persona_juridica_telefono',
  VALIDACION_PERSONA_JURIDICA_CODIGO: 'validacion_persona_juridica_codigo',
  VALIDACION_PERSONA_JURIDICA_DNI_FECHA_NACIMIENTO:
    'valida_persona_juridica_dni_fecha_nac',
  VALIDACION_PERSONA_JURIDICA_FOTO_SELFIES:
    'validacion_persona_juridica_foto_selfies',
  VALIDACION_PERSONA_JURIDICA_FOTO_DNI: 'validacion_persona_juridica_foto_dni',
  VALIDACION_PERSONA_JURIDICA_REGULACIONES:
    'validacion_persona_juridica_regulaciones',
  VALIDACION_PERSONA_JURIDICA_DOCUMENTACION:
    'validacion_persona_juridica_documentos',
  VALIDACION_PERSONA_JURIDICA_EXITO: 'validacion_persona_juridica_exito',
  VALIDACION_PERSONA_FISICA: 'validacion_persona_fisica',
  VALIDACION_PERSONA_FISICA_TELEFONO: 'validacion_persona_fisica_telefono',
  VALIDACION_PERSONA_FISICA_CODIGO: 'validacion_persona_fisica_codigo',
  VALIDACION_PERSONA_FISICA_DNI_FECHA_NACIMIENTO:
    'validacion_persona_fisica_dni_fecha_nac',
  VALIDACION_PERSONA_FISICA_FOTO_SELFIES:
    'validacion_persona_fisica_foto_selfies',
  VALIDACION_PERSONA_FISICA_FOTO_DNI: 'validacion_persona_fisica_foto_dni',
  VALIDACION_PERSONA_FISICA_REGULACIONES:
    'validacion_persona_fisica_regulaciones',
  VALIDACION_PERSONA_FISICA_EXITO: 'validacion_persona_fisica_exito',
  CONTRATOS_INICIAR_OPERACION: 'contratos_iniciar_operacion',
  OPERACION_CONTACTO_WHATSAPP: 'operacion_contacto_whatsapp',
  OPERACION_VENTA_PRECIO_HECHO: 'operacion_venta_precio_hecho',
  OPERACION_VENTA_PRECIO_HECHO_EXITO: 'operacion_venta_precio_hecho_exito',
  OPERACION_CANJE_PRECIO_HECHO: 'operacion_canje_precio_hecho',
  OPERACION_CANJE_PRECIO_HECHO_EXITO: 'operacion_canje_precio_hecho_exito',
  OPERACION_VENTA_PRECIO_A_FIJAR: 'operacion_venta_precio_a_fijar',
  OPERACION_VENTA_PRECIO_A_FIJAR_EXITOSA:
    'operacion_venta_precio_a_fijar_exitosa',
  OPERACION_CANJE_PRECIO_A_FIJAR: 'operacion_canje_precio_a_fijar',
  OPERACION_CANJE_PRECIO_A_FIJAR_EXITOSA:
    'operacion_canje_precio_a_fijar_exitosa',
  CONTRATO_DETALLE_CONFIRMA: 'contrato_detalle_confirma',
  CONTRATO_DETALLE_CONFIRMA_ACEPTAR_OFERTA:
    'contrato_detalle_confirma_aceptar_oferta',
  CONTRATO_DETALLE_CONFIRMA_RECHAZA_OFERTA:
    'contrato_detalle_confirma_rechaza_oferta',
  CONTRATO_REALIZAR_CONTRAOFERTA: 'contrato_realizar_contraoferta',
  CONTRATO_DETALLE_CUPOS: 'contrato_detalle_cupos',
  CONTRATO_DETALLE_CUPOS_RESUMEN: 'contrato_detalle_cupos_resumen',
  CONTRATO_DETALLE_CUPOS_SOLICITAR: 'contrato_detalle_cupos_solicitar',
  CONTRATO_DETALLE_CUPOS_SOLICITAR_EXITOSA:
    'contrato_detalle_cupos_solicitar_exitosa',
  CONTRATO_DETALLE_APLICACIONES: 'contrato_detalle_aplicaciones',
  CONTRATO_DETALLE_LIQUIDACIONES: 'contrato_detalle_liquidaciones',
  CONTRATO_DETALLE_FIJACION: 'contrato_detalle_fijacion',
  CONTRATO_DETALLE_NUEVA_FIJACION: 'contrato_detalle_nueva_fijacion',
  CONTRATO_DETALLE_NUEVA_FIJACION_EXITO:
    'contrato_detalle_nueva_fijacion_exito',
  CONTRATO_DETALLE_FIJACION_PESIFICACION:
    'contrato_detalle_fijacion_pesificacion'
}

export function format2Digits (input: any) {
  return input.toLocaleString('en-US', {
    minimumIntegerDigits: 2,
    useGrouping: false
  })
}

export function getMovementIsDebit (movementName: string) {
  return !!movementName.includes('Débito')
}

export const getRandomKey = () => Date.now() * Math.random()

export enum ErrorMessages {
  DEFAULT_BACKEND_ERROR = 'Error',
  LOGIN_VALIDATION_ERROR = 'E-mail y/o contraseña incorrectos.',
  REGISTER_ERROR_DEFAULT = 'Error al registrar la cuenta',
  REGISTER_CUIT_VALIDATION_ERROR = 'CUIT ya registrado.',
  USER_BLOCKED = 'El usuario se encuentra bloqueado.',
  USER_EXPIRED = 'El usuario se encuentra vencido.',
  PASSWORD_PROHIBITED = 'Su contraseña no puede incluir datos personales',
  USER_SUSPENDED = 'Cuenta suspendida, contactar a info@qirapagos.com',
  INVALID_CUIT_ERROR = 'CUIT/CUIL inválido',
  DEFAULT_INPUT_ERROR = 'Ha ocurrido un problema, revisá los datos ingresados',
  DEFAULT_REQUEST_ERROR = 'El CUIT/CUIL ingresado no corresponde a un representante.',
  PHOTO_UPLOAD_ERROR = 'Error al subir la imagen',
  TRANSFER_ERROR = 'Error al realizar la transferencia, intentá más tarde.',
  INVALID_DNI_IMAGE_ERROR = 'Foto de DNI Inválida, por favor reintentá nuevamente',
  DEFAULT_REQUEST_DOCUMENTS = 'Error al obtener los documentos',
  DEFAULT_REQUEST_CONTRACTS = 'Error al obtener los contratos',
  NO_EMAIL_REGISTER = 'El email no se encuentra registrado',
  ERROR_SUSPEND = 'Error al intentar suspender la cuenta.',
  EMAIL_EDIT_ERROR = 'Ocurrió un error al editar el e-mail. Por favor, volvé a intentarlo.',
  EMAIL_USED = 'El email ingresado ya existe, ingresá uno nuevo.',
  PHONE_EDIT_ERROR = 'Ocurrió un error al editar el teléfono. Por favor, volvé a intentarlo.',
  PHONE_USED = 'El teléfono ya está registrado.',
  ALIAS_EDIT = 'Ocurrió un error al editar el alias. Por favor, volvé a intentarlo.',
  ADDRESS_EDIT = 'Ocurrió un error al editar la dirección. Por favor, volvé a intentarlo.'
}

// For comprehension  ID 33 = retention, ID 7 = Perception, ID 1 = Debit transfer, ID 2 = Credit transfer
export const generateReceiptShare = (
  receipt: any,
  formatedDate: string,
  formatedAmount: string
) => {
  const {
    cbu,
    owner,
    cuit,
    concept,
    id,
    creditAccount,
    movementType,
    debitAccount
  } = receipt
  const numberByThousands = formatNumbersHelper(formatedAmount)
  if (movementType.id === 1) {
    return `FECHA DE TRANSFERENCIA: ${formatedDate} \nCUENTA DÉBITO: ${
      creditAccount || 'Sin datos'
    } \nMONTO: $${numberByThousands} \nTRANSFERIDO A: ${
      owner || 'Sin datos'
    } \nCBU DESTINO: ${cbu || 'Sin datos'} \nCUIT: ${
      cuit || 'Sin datos'
    } \nCONCEPTO: ${concept || 'Varios'} \nNUMERO DE OPERACIÓN: ${id}`
  }
  if (movementType.id === 2) {
    return `FECHA DE TRANSFERENCIA: ${formatedDate} \nCUENTA REMITENTE: ${
      creditAccount || 'Sin datos'
    } \nMONTO: $${numberByThousands} \nRECIBIDO DE: ${
      owner || 'Sin datos'
    } \nCBU/CVU REMITENTE: ${cbu || 'Sin datos'} \nCUIT: ${
      cuit || 'Sin datos'
    } \nCONCEPTO: ${concept || 'Varios'} \nNUMERO DE OPERACIÓN: ${id}`
  }
  if (movementType.id === 7) {
    return `FECHA: ${formatedDate} \nCUENTA DÉBITO: ${
      debitAccount || 'Sin datos'
    } \nMONTO: $${numberByThousands} \nCONCEPTO: ${
      concept || 'PERCEPCIÓN RECIBIDA POR \nDECRETO 301/2021'
    } \nNUMERO DE OPERACIÓN: ${id}`
  }
  if (movementType.id === 33) {
    return `FECHA: ${formatedDate} 
    \nMONTO: $${numberByThousands} \nCONCEPTO: ${
      concept || 'RETENCIÓN REALIZADA POR DECRETO 301/2021'
    } \nNUMERO DE OPERACIÓN: ${id}`
  }
}
